<template>
  <div class="container">
    <div class="section">
      <div class="edit-wrap">
        <div class="notification is-danger">
          The error occured. <a href="https://xgames.zone">Back to xGames.</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Sentry from "@sentry/browser";

export default {
  name: 'Error',
  mounted () {
    Sentry.captureException('Error confirming subscription');
  },
};
</script>

<style scoped>
  a {
    color: #FFF !important;
  }
</style>
