<template>
  <div class="container">
    <div class="section">
      <div class="edit-wrap">
        <div class="notification is-success">
          Your subscription has been confirmed. <a href="https://xgames.zone">Back to xGames.</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Success',
  mounted () {},
};
</script>

<style scoped>
  a {
    color: #FFF !important;
  }
</style>
