const fetch = require('node-fetch');

const endpoint = 'https://xgames.zone/wp-json';

const getTags = async () => {
  const data = await Promise.all([
    fetch(`${endpoint}/wp/v2/tags?context=view&orderby=count&order=desc&per_page=100`, {
      headers: {},
    }).then((res) => res.json()),
    fetch(`${endpoint}/wp/v2/tags?context=view&orderby=count&order=desc&per_page=100&page=2`, {
      headers: {},
    }).then((res) => res.json())
  ]);

  return [
    ...data[0],
    ...data[1],
  ];
}

const getFilteredCount = async (tagIds = [], query = '', taxRelation = 'AND') => {
  const response = await fetch(`${endpoint}/wp/v2/posts?context=embed&search=${query}&tax_relation=${taxRelation}&per_page=1${tagIds.map((i) => `&tags[]=${i}`).join('')}`, {
    headers: {},
  })

  return response.headers.get('x-wp-total');
}

module.exports = {
    getTags,
    getFilteredCount
};
