import Vue from "vue";
import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";
import '../node_modules/bulma/css/bulma.css';
import App from './App.vue'
import router from './router';

Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: "https://f477c35422ed47769f8d480b911d901e@o220965.ingest.sentry.io/5564101",
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
