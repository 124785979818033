const fetch = require('node-fetch');

const endpoint = 'https://api.xgames.zone';

const getXgamesData = (id) => fetch(`${endpoint}/admin/xgames?id=${id}`, {
  headers: {},
}).then((res) => res.json());

const getItem = (id, project) => fetch(`${endpoint}/admin/get?id=${id}&${project ? `project=${project}` : ''}`, {
  headers: {},
}).then((res) => res.json());

const create = (xgames_id, data) => fetch(`${endpoint}/admin/gameplay/create`, {
  method: 'post',
  body: JSON.stringify({
    xgames_id,
    ...data,
  }),
  headers: {
    'Content-Type': 'application/json',
  },
});

const subscribe = (data) => fetch(`${endpoint}/subscription/subscribe`, {
  method: 'post',
  body: JSON.stringify(data),
  headers: {
    'Content-Type': 'application/json',
  },
});

module.exports = {
  getXgamesData,
  getItem,
  create,
  subscribe,
};
