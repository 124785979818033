<template>
  <div class="edit-wrap">
    <h1 class="title is-4">Search</h1>
    <div class="edit-form" onsubmit="return false">
      <form>
        <div class="field is-expanded">
          <div class="field has-addons">
            <div class="control is-expanded has-icons-left has-icons-right">
              <input
                class="input is-fullwidth"
                v-model="model.query"
                type="text"
                placeholder="puzzle 3d game"
                v-on:keyup="searchTimeOut()"
              />
              <span class="icon is-medium is-left">
                <i class="fas fa-search"></i>
              </span>
            </div>
            <div class="control">
              <button class="button is-link is-primary" v-on:click="goToResults()">Search</button>
            </div>
          </div>
          <p class="help is-pulled-right"><b class="light">{{ filteredCount }}</b> results{{ modelChanged ? ' expected' : '' }}</p>
          <p class="help">Search thruogh game titles and descriptions and select <b>multi-tags</b></p>
        </div>
        <div class="has-text-centered" v-if="!Object.keys(tags).length"><Loader /></div>
        <div class="tags-wrap" v-if="Object.keys(tags).length">
          <div class="has-text-centered" v-if="shortView && model.tags.length === 0">
            <a href="javascript:void(0)" class="has-text-weight-normal" v-on:click="showAllClick()">
              <small><i class="fas fa-angle-down"></i><span class="mx-2">show tags</span><i class="fas fa-angle-down"></i></small>
            </a>
          </div>
          <div v-else>
            <div class="tags-header mb-2" v-if="!shortView">
              <a href="javascript:void(0)" class="is-pulled-right selected" v-if="model.tags.length !== 0" v-on:click="clearTags()">clear</a>
              <div class="text-switcher">
                <a href="javascript:void(0)" :class="alphabetical === false ? 'selected' : ''" v-on:click="alphabetical = false">By popularity</a>
                <a href="javascript:void(0)" :class="alphabetical === true ? 'selected' : ''" v-on:click="alphabetical = true">Alphabetically</a>
              </div>
            </div>
            <div class="field is-grouped is-grouped-multiline">
              <div class="control" v-for="(item, i) in getSortedTags()" :key="i" v-on:click="tagClick(item)">
                <div class="tags has-addons">
                  <span :class="'tag is-medium ' + (model.tags.indexOf(item.id) !== -1 ? 'is-primary' : 'is-dark')">{{ item.name.replace('&amp;', '&') }}</span>
                  <span class="tag is-medium is-dark"><small>{{ item.count }}</small></span>
                </div>
              </div>
              <div class="control" v-if="shortView">
                <div class="tags">
                  <span class="tag is-medium is-dark px-4" v-on:click="showAllClick"><small>Show all tags >></small></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Loader from '../components/Loader.vue';
import router from '../router';
import { getTags, getFilteredCount } from '../services/wpapi';

export default {
  name: 'Search',
  router,
  components: {
    Loader,
  },
  data() {
    return {
      tags: {},
      timer: null,
      shortView: false,
      filteredCount: '',
      modelChanged: false,
      alphabetical: false,
      model: {
        query: '',
        tags: []
      },
    };
  },
  mounted () {
    const queriedTags = [...(Array.isArray(this.$route.query['tag[]']) ? this.$route.query['tag[]'] : [this.$route.query['tag[]']] || [])];
    Object.keys(this.$route.query).forEach((tagName) => {
      if (tagName !== 'tag[]' && tagName.indexOf('tag[') === 0) {
        queriedTags.push(this.$route.query[tagName]);
      }
    })

    if (this.$route.query.s || (queriedTags.length !== 0)) {
      this.shortView = true;
    }

    this.model.query = this.$route.query.s || '';

    Promise.all([
      this.loadTags(),
    ]).then(() => {
      this.model.tags = Object.values(this.tags).map((tag) => {
        return queriedTags.indexOf(tag.slug) !== -1 ? tag.id : null
      }).filter((item) => item);
    }).then(() => this.updateFilteredCount());
  },
  methods: {
    getSortedTags() {
      let tags = [];
      if (this.alphabetical) {
        tags = Object.values(this.tags).sort((a,b) => a.name.localeCompare(b.name));
      } else {
        tags = Object.values(this.tags).sort((a,b) => (a.count > b.count) ? -1 : ((b.count > a.count) ? 1 : 0));
      }
      return tags.filter((item) => {
         return !this.shortView || this.model.tags.indexOf(item.id) !== -1
      })
    },
    loadTags() {
      return getTags()
        .then((data) => {
          this.tags = {};
          data.forEach((item) => {
            this.tags[item.id] = {
              id: item.id,
              name: item.name,
              slug: item.slug,
              count: item.count,
            };
          });
        })
        .catch(() => {
          alert('Something went wrong, please refresh the page')
        })
    },
    updateFilteredCount() {
      this.filteredCount = '• • • ';
      return getFilteredCount(this.model.tags, this.model.query)
        .then((val) => {
          this.filteredCount = val
        })
        .catch(() => {})
    },
    tagClick(item) {
      this.modelChanged = true;
      if (this.model.tags.indexOf(item.id) === -1) {
        this.model.tags.push(item.id);
      } else {
        this.model.tags.splice(this.model.tags.indexOf(item.id), 1);
      }
      this.updateFilteredCount();
    },
    searchTimeOut() {
      this.modelChanged = true;
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(this.updateFilteredCount, 700);
    },
    goToResults() {
      location.href = `/?s=${this.model.query}${this.model.tags.map((i) => `&tag[]=${this.tags[i].slug}`).join('')}`;
    },
    showAllClick() {
      this.shortView = false
    },
    clearTags() {
      this.model.tags = [];
    },
    reset() {
      //
    },
  },
};
</script>

<style scoped>
  #app .control .icon { color: rgba(255, 255, 255, 0.4); }
  .edit-wrap {
    max-width: 980px;
  }
  .tags .tag {
    font-size: 0.9rem;
    cursor: pointer;
  }
  .tags > .tag:first-child {
    padding-right: 8px;
    border-right: 1px solid rgba(0,0,0,0.3);
    background: rgba(255,255,255,0.1);
  }
  .tags:hover > .tag.is-dark:first-child {
    background: rgba(255,255,255,0.13);
  }
  .tags > .tag:last-child {
    padding-left: 6px;
    background: rgba(255,255,255,0.05);
  }
  .tags:hover > .tag:last-child {
    background: rgba(255,255,255,0.08);
  }
  #app .tags-header a {
    color: rgba(255,255,255,0.8);
    font-size: 0.75rem;
  }
  #app .tags-header a.selected {
    color: rgba(255,255,255,1);
    font-weight: bold;
    border-bottom: 1px solid #76252d;
  }
  #app .text-switcher a {
    padding: 0 3px;
  }
</style>
