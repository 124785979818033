<template>
  <div class="container">
    <div class="section">
      <div class="edit-wrap">
        <h1 class="title is-4">Subscribe to our updates</h1>
        <h2 class="subtitle is-6">Receive daily/bi-weekly updates with the last games right to your mailbox</h2>
        <p class="content">
          We will send you our site updates every <i class="light">day</i> or every<i class="light"> 3 days</i>. 
        </p>
        <hr />
        <div class="edit-form" :class="{ 'locked': loader }" onsubmit="return false">
          <div class="notification is-success" v-if="subscribed">
            Thank you! You have been successfully subscribed to xGames updates!
            <br />Confim your subscription by following the link we've sent you. 
          </div>
          <form v-if="!subscribed">
            <div class="notification is-danger" v-if="error">
              {{ error }}
            </div>
            <div class="field">
              <label class="label">E-mail:</label>
              <p class="help">Provide your email to receive updates <span class="highlighted">directly to you mailbox</span></p>
              <div class="control has-icons-right" :class="validate('email') ? 'valid' : ''">
                <input
                  class="input"
                  v-model="model.email"
                  type="text"
                  placeholder="email@example.com"
                />
                <span class="icon is-medium is-right">
                  <i class="fas fa-check"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="label">Subscription type:</label>
              <p class="help">You can choose a subscription type. It's up to you to choose the frequency of your emails.</p>
              <div class="control columns">
                <label class="radio column is-one-third">
                  <input type="radio" checked v-model="model.freq" class="checkbox-block-input" value="1">
                  <div class="checkbox-block">
                    <span class="icon is-medium is-right">
                      <i class="fas fa-check"></i>
                    </span>
                    Daily
                  </div>
                </label>
                <label class="radio column is-one-third">
                  <input type="radio" v-model="model.freq" class="checkbox-block-input" value="3">
                  <div class="checkbox-block">
                    <span class="icon is-medium is-right">
                      <i class="fas fa-check"></i>
                    </span>
                    Once in a 3 days
                  </div>
                </label>
                <!--label class="radio column">
                  <input type="radio" v-model="model.freq" class="checkbox-block-input" value="7">
                  <div class="checkbox-block">
                    <span class="icon is-medium is-right">
                      <i class="fas fa-check"></i>
                    </span>
                    Weekly
                  </div>
                </label-->
              </div>
            </div>
            <div class="field">
              <label class="label">Safe mode?:</label>
              <p class="help">We provide an option to receive updates with blurred images (in case you don't want to see NSFW images in your mailbox).</p>
              <div class="control columns">
                <label class="radio column is-one-third">
                  <input type="radio" checked v-model="model.safeMode" class="checkbox-block-input" value="0">
                  <div class="checkbox-block">
                    <span class="icon is-medium is-right">
                      <i class="fas fa-check"></i>
                    </span>
                    Normal version
                  </div>
                </label>
                <label class="radio column is-one-third">
                  <input type="radio" v-model="model.safeMode" class="checkbox-block-input" value="1">
                  <div class="checkbox-block">
                    <span class="icon is-medium is-right">
                      <i class="fas fa-check"></i>
                    </span>
                    Safe-mode version
                  </div>
                </label>
              </div>
            </div>
            <div class="field is-grouped is-grouped-right">
              <div class="control" v-if="loader">
                <Loader />
              </div>
              <div class="control has-icons-right">
                <button class="button is-link is-primary" :disabled="!validate() || loader" v-on:click="subscribeBtn">
                  {{ this.$route.query.email ? 'Update subscription' : 'Subscribe' }}
                </button>
              </div>
            </div>
            <p class="help has-text-right" v-if="validate()">By submitting this form you agree to receive emails from xgames.zone</p>
          </form>
          <hr />
          <h1 class="title is-5">In case of problems or questions</h1>
          <h2 class="subtitle is-6">Feel free to contact us via e-mail</h2>
          <p class="content">
            Feel free to write to xgameszone[at]protonmail.com if you have any problems, questions or suggestions.
          </p>
          <hr />
          <div class="content is-small" style="text-align:center;">&copy; xGames.zone</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Sentry from "@sentry/browser";
import { subscribe } from '../../services/api';
import Loader from '../../components/Loader.vue';

const validation = {
  freq: (val) => [1,3,7].includes(parseInt(val)),
  safeMode: () => true,
  tz: () => true,
  email: (val) => val.indexOf('@') !== -1 && val.indexOf('.') !== -1,
};

export default {
  name: 'Subscribe',
  components: {
    Loader,
  },
  data() {
    return {
      loader: false,
      subscribed: false,
      error: '',
      model: {
        email: this.$route.query.email || '',
        safeMode: 0,
        freq: 1,
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    };
  },
  mounted () {
    this.model.tz = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
  },
  methods: {
    reset() {
      this.loader = false;
      this.model = {
        email: '',
        safeMode: '',
        freq: '',
        tz: '',
      };
    },
    validate(f) {
      if (f) {
        return validation[f](this.model[f]);
      }

      for (let r of Object.keys(validation)) {
        if (!validation[r](this.model[r])) {
          return false;
        }
      }

      return true;
    },
    async subscribeBtn() {
      this.loader = true;
      this.error = '';
      this.subscribed = false;
      subscribe({
        email: this.model.email,
        safeMode: this.model.safeMode,
        freq: this.model.freq,
        tz: this.model.tz,
      }).then(async (res) => {
        if (res.status !== 200) {
          const dat = res && typeof res.json === 'function' ? await res.json() : null;
          Sentry.captureException('Error submitting subscribe form', { extra: {
            data: JSON.stringify(this.model)
          }});
          this.error = dat || 'Unable to subscribe. Unknown error.';
          this.loader = false;
          return null;
        }
        this.loader = false;
        this.subscribed = true;
        const dat = await res.json();
        console.log(dat)
        Sentry.captureException('Successfully submitted form', { extra: { data: dat }});
        this.loader = false;
        return null;
      }).catch(async (res) => {
        const dat = res && typeof res.json === 'function' ? await res.json() : null;
        Sentry.captureException('Error submitting subscribe form', { extra: {
          data: JSON.stringify(this.model)
        }});
        this.error = dat || 'Unable to subscribe. Unknown error.';
        this.loader = false;
        return null;
      });
    },
  },
};
</script>

<style scoped>
  i {
    font-style: normal;
  }
  .field {
    margin-bottom: 30px;
  }
  .radio + .radio {
    margin:0;
  }
  label + p {
    margin-bottom: 10px;
  }
  .checkbox-block {
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 0 20px -2px rgba(0,0,0,0.3);
    padding: 5px;
    border-radius: 3px;
  }
  .checkbox-block-input {
    position: absolute;
    visibility: hidden;
    margin: 13px 0 0 10px;
  }
  .checkbox:hover, .radio:hover {
    color: #CCC;
  }
  .checkbox-block .icon {
    color: rgba(255,255,255,0.2) !important;
  }
  input[type=checkbox]:checked+.checkbox-block,
  input[type=radio]:checked+.checkbox-block {
    box-shadow: 0 0 20px -2px rgba(0,0,0,0.3), 0 0 0 1px #dc324a;
  }
  input[type=checkbox]:checked+.checkbox-block .icon,
  input[type=radio]:checked+.checkbox-block .icon {
    color: #dc324a !important;
  }
</style>
