<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import './assets/styles/index.css';

export default {}
</script>
